import { Tabs, Col, Row, Typography } from "antd";
import { useQuery } from "@apollo/client";

import ListingCard from "../components/ListingCard";
import { Query } from "../apollo/types";
import { GET_TRIPS } from "../apollo/queries";

const { TabPane } = Tabs;
const { Paragraph } = Typography;

const MEDIA_QUERY = "only screen and (max-width: 768px)";

export default function TripsPage() {
  const isMobile = window.matchMedia(MEDIA_QUERY).matches;
  const { data, error, loading } = useQuery<Query>(GET_TRIPS);

  if (error) {
    return (
      <Paragraph style={{ textAlign: "center" }}>{error.message}</Paragraph>
    );
  }

  if (loading) {
    return <Paragraph style={{ textAlign: "center" }}>Loading...</Paragraph>;
  }

  if (data) {
    const { trips } = data;
    return (
      <Tabs
        defaultActiveKey="upcoming-trips"
        size="small"
        tabPosition={isMobile ? "top" : "left"}
      >
        <TabPane tab="Upcoming Trips" key={"upcoming-trips"}>
          <Row
            style={{ width: " 100%", margin: "50px", overflow: "hidden" }}
            gutter={[24, 16]}
            className="trips-history-row"
          >
            {trips.length > 0 &&
              trips.map((trip) => {
                const { id, thumbnail, title, description, budget, author } =
                  trip!;
                return (
                  <Col key={id} xs={24} md={6}>
                    <ListingCard
                      thumbnail={thumbnail!}
                      title={title!}
                      description={description!}
                      budget={budget!}
                      author={{
                        firstName: author!.firstName!,
                        lastName: author!.lastName!,
                        avatar: author!.avatar!,
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </TabPane>
        <TabPane tab="Previous Trips" key={"previous-trips"}>
          No previous trips 😥 😥
        </TabPane>
      </Tabs>
    );
  }
  return null;
}
