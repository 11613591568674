import * as React from "react";

let name = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser") as string)
  : "";
const token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token") as string)
  : "";

export type User = {
  name: string;
  token: string;
  loading: boolean;
  errorMessage?: string | null;
};

export type AuthAction =
  | { type: "REQUEST_LOGIN"; loading: boolean }
  | { type: "LOGIN_SUCCESS"; data: User }
  | { type: "LOGOUT" }
  | { type: "LOGIN_ERROR"; errorMessage: string }
  | { type: "default" };

export const initialState: User = {
  name: "" || name,
  token: "" || token,
  loading: false,
  errorMessage: null,
};

export const AuthReducer: React.Reducer<User, AuthAction> = (
  initialState,
  action
) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return { ...initialState, loading: true };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        name: action.data.name,
        token: action.data.token,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...initialState,
        name: "",
        token: "",
        loading: false,
      };
    case "LOGIN_ERROR":
      return {
        ...initialState,
        errorMessage: action.errorMessage,
      };
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
};
