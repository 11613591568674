import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { schemaLink } from "./schema";

export const cache = new InMemoryCache();

const GRAPHQL_ENDPOINT = "http://localhost:4000/";

const link = createHttpLink({ uri: GRAPHQL_ENDPOINT });

export const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([link, schemaLink as unknown as ApolloLink]),
  cache,
  resolvers: {},
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
  },
});
