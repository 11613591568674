import type { ReactNode, FC } from "react";
import { MockedProvider } from "@apollo/client/testing";
import { ApolloProvider } from "@apollo/client";
import { client } from "../apollo/config";
import {
  myTripsQueryMock,
  profileQueryMock,
  tripsQueryMock,
} from "../apollo/mocks";

interface ProviderProps {
  children: ReactNode;
  useMocks?: boolean;
}

export const GraphQLProvider: FC<ProviderProps> = ({ useMocks, children }) => {
  if (useMocks)
    return (
      <MockedProvider
        mocks={[tripsQueryMock, profileQueryMock, myTripsQueryMock]}
      >
        <>{children}</>
      </MockedProvider>
    );
  return (
    <ApolloProvider client={client}>
      <>{children}</>
    </ApolloProvider>
  );
};
