import { Modal, Typography, Row, Input, Button, Col } from "antd";
import { AuthProps } from "./Login";

const { Title } = Typography;
export default function SignUp({ visible, closeModal }: AuthProps) {
  return (
    <Modal
      visible={visible}
      closable={true}
      className="auth-modal"
      onCancel={closeModal}
      footer={false}
      width={800}
    >
      <Title level={3}>Create New Account</Title>
      <Row>
        <Col md={11}>
          <Input placeholder="Enter first name" name="firstName" />
        </Col>
        <Col md={11} offset={2}>
          <Input placeholder="Enter last name" />
        </Col>
        <Input placeholder="Enter email address" type={"email"} />
        <Input placeholder="Enter password" type={"password"} />
        <Input placeholder="Repeat password" type={"password"} />
        <Button block>Create Account</Button>
      </Row>
    </Modal>
  );
}
