import * as React from "react";
import { Menu, Layout, Dropdown } from "antd";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
const { Header } = Layout;

type Key = "login" | "signup";

export default function Navbar() {
  const [loginVisibility, setLoginVisibility] = React.useState(false);
  const [signUpVisibility, setSignUpVisibility] = React.useState(false);
  const [loggedIn, _setLoggedIn] = React.useState(false);

  const handleMenuItemClick = (key: Key): void => {
    if (key === "login") {
      setLoginVisibility(true);
      return;
    }
    setSignUpVisibility(true);
  };

  const closeLoginModal = () => setLoginVisibility(false);
  const closeSignupModal = () => setSignUpVisibility(false);

  const loginMenu = (
    <>
      <Menu.Item>Profile</Menu.Item>
      <Menu.Item>Trips</Menu.Item>
      <Menu.Item>Log out</Menu.Item>
    </>
  );
  const gaEventTracker = useAnalyticsEventTracker("Navbar");
  return (
    <>
      <Layout className="layout">
        <Header
          style={{ background: "#fff", borderBottom: "1px solid #f0f0f0" }}
        >
          <h1 className="logo">
            nenda<span>.africa</span>
          </h1>
          <Menu mode="horizontal" style={{ justifyContent: "flex-end" }}>
            <Menu.Item>
              {loggedIn ? (
                <Dropdown trigger={["click"]} overlay={loginMenu}>
                  <a onClick={(e) => e.preventDefault()}>Henry Onyango</a>
                </Dropdown>
              ) : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleMenuItemClick("login");
                    gaEventTracker("Login", "Click");
                  }}
                >
                  Log in
                </a>
              )}
            </Menu.Item>
            {!loggedIn ? (
              <Menu.Item
                onClick={() => {
                  handleMenuItemClick("signup");
                  gaEventTracker("Signup", "Click");
                }}
              >
                Create account
              </Menu.Item>
            ) : null}
          </Menu>
        </Header>
      </Layout>
      <Login visible={loginVisibility} closeModal={closeLoginModal} />
      <SignUp visible={signUpVisibility} closeModal={closeSignupModal} />
    </>
  );
}
