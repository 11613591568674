import { Layout } from "antd";
import ReactGA from "react-ga";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FooterPage from "./components/Footer";
import Navbar from "./components/Navbar";
import { Home } from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import TripsPage from "./pages/TripsPage";
import { AuthProvider } from "./context";
import { GraphQLProvider } from "./components/GraphQLProvider";
import { useEffect } from "react";

const trackingID = process.env.REACT_APP_ANALYTICS_ID || "";
ReactGA.initialize(trackingID);

export default function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <AuthProvider>
      <GraphQLProvider useMocks>
        <Router>
          <Layout>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/trips" element={<TripsPage />} />
            </Routes>
            <FooterPage />
          </Layout>
        </Router>
      </GraphQLProvider>
    </AuthProvider>
  );
}
