import { SchemaLink } from "@apollo/client/link/schema";
import { makeExecutableSchema } from "@graphql-tools/schema";

import gql from "graphql-tag";

const typeDefs = gql`
  #Queries
  type Query {
    trips: [Trip]!
    getProfile(userId: ID!): ProfileResponse!
    getMyTrips(userId: ID!): [Trip]!
  }

  type ProfileResponse {
    user: User
    error: String
  }

  #Mutations
  type Mutation {
    login(email: String!, password: String!): AuthResponse!
    signup(input: SignupInput!): AuthResponse!
    joinTrip(input: TripInput!): TripResponse!
    cancelTrip(input: TripInput!): CancelTripResponse!
    createTrip(input: CreateTripInput!): TripResponse!
  }

  #Inputs
  "Input for leaving or joining a trip"
  input TripInput {
    trip: ID!
    user: ID!
  }
  input ItineraryInput {
    day: Int!
    activity: String!
  }
  input CreateTripInput {
    userId: ID!
    title: String
    budget: Int
    startDate: Date
    endDate: Date
    description: String
    availableSlots: Int
    itinerary: [ItineraryInput]
  }

  "Input for signup"
  input SignupInput {
    firstName: String!
    lastName: String!
    email: String!
    password: String!
    confirmPassword: String!
  }

  #Types
  scalar Date

  # Todo: Confirm that right states are being represented
  type AuthResponse {
    user: User
    error: String
    token: String
  }
  # Todo: Refactor to use interface
  type TripResponse {
    status: Boolean
    error: String
    trip: Trip
  }

  type CancelTripResponse {
    status: Boolean
    error: String
  }

  type Trip {
    id: ID
    "Thumbnail image for the trip"
    thumbnail: String
    "Title for the trip"
    title: String
    "the user who created the trip"
    author: User
    "Estimated cost for the trip"
    budget: Int
    "Date describing when the trip starts"
    startDate: Date
    "Date describing when the trip ends"
    endDate: Date
    "Description for the trip"
    description: String
    "Slots available for the trip"
    availableSlots: Int
    "Activities planned for the trip"
    itinerary: [Itinerary]
    "Users who have joined the trip"
    party: [User]
  }

  type User {
    id: ID
    firstName: String
    lastName: String
    email: String
    avatar: String
    verified: Boolean
    isAgency: Boolean
    createdTrips: [Trip]
    previousTrips: [Trip]
    upcomingTrips: [Trip]
    password: String
  }
  type Itinerary {
    "The day of the itinerary"
    day: Int!
    "Activity which will be done during that particular day"
    activity: String!
  }
`;

export const schemaLink = new SchemaLink({
  schema: makeExecutableSchema({ typeDefs }),
});
