import ReactGA from "react-ga";

const useAnalyticsEventTracker = (eventCategory: string) => {
  const eventTracker = (eventAction: string, eventLabel: string) => {
    ReactGA.event({
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
    });
  };
  return eventTracker;
};

export default useAnalyticsEventTracker;
