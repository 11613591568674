import { Row, Col, Typography } from "antd";
import { useQuery } from "@apollo/client";
import ListingCard from "../components/ListingCard";
import { GET_TRIPS } from "../apollo/queries";
import { Query } from "../apollo/types";

const { Title, Paragraph } = Typography;

export default function Listings(): JSX.Element | null {
  const { data, error, loading } = useQuery<Query>(GET_TRIPS);

  if (error) {
    return (
      <Paragraph style={{ textAlign: "center" }}>{error.message}</Paragraph>
    );
  }

  if (loading) {
    return <Paragraph style={{ textAlign: "center" }}>Loading...</Paragraph>;
  }

  if (data) {
    const { trips } = data;

    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "80%",
          padding: "16px",
        }}
        className="listings-container"
      >
        <Row justify="start" className="listings-row">
          <Typography>
            <Title
              style={{
                color: "#484848",
                fontSize: "24px",
                marginBottom: "4px",
              }}
            >
              Discover the community's catalog
            </Title>
            <Title
              level={3}
              style={{
                color: "#484848",
                fontWeight: "normal",
                marginTop: "0px",
                fontSize: "16px",
              }}
            >
              Browse through planned travels created by current community
              members
            </Title>
          </Typography>
          <Row style={{ width: " 100%" }} gutter={[24, 16]}>
            {trips.length > 0 &&
              trips.map((trip) => {
                // If length is more than zero, then trip has data
                const { id, thumbnail, title, description, budget, author } =
                  trip!;
                return (
                  <Col key={id} xs={24} sm={24} md={6}>
                    <ListingCard
                      thumbnail={thumbnail!}
                      title={title!}
                      description={description!}
                      budget={budget!}
                      author={{
                        firstName: author!.firstName!,
                        lastName: author!.lastName!,
                        avatar: author!.avatar!,
                      }}
                    />
                  </Col>
                );
              })}
          </Row>
        </Row>
      </div>
    );
  }
  return null;
}
