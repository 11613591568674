import gql from "graphql-tag";

export const GET_TRIPS = gql`
  query Trips {
    trips {
      author {
        avatar
        email
        firstName
        lastName
      }
      availableSlots
      budget
      description
      endDate
      id
      itinerary {
        activity
        day
      }
      startDate
      title
      thumbnail
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile($userId: ID!) {
    getProfile(userId: $userId) {
      user {
        id
        firstName
        lastName
        email
      }
      error
    }
  }
`;

export const GET_MY_TRIPS = gql`
  query getMyTrips($userId: ID!) {
    getMyTrips(userId: $userId) {
      id
      title
      description
      budget
      startDate
      endDate
      availableSlots
      itinerary {
        day
        activity
      }
      party {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
