import * as Factory from "factory.ts";
import { faker } from "@faker-js/faker";
import { Trip, User, Query, Itinerary } from "./types";
import { MockedResponse } from "@apollo/client/testing";
import { GET_TRIPS, GET_PROFILE } from "./queries";

export const UserMock = Factory.Sync.makeFactory<User>({
  __typename: "User",
  id: Factory.each(() => faker.datatype.uuid()),
  firstName: Factory.each(() => faker.name.firstName()),
  lastName: Factory.each(() => faker.name.lastName()),
  email: Factory.each(() => faker.internet.email()),
  avatar: Factory.each(() => faker.image.avatar()),
});

export const ItineraryMock = Factory.Sync.makeFactory<Itinerary>({
  __typename: "Itinerary",
  day: Factory.each(() => faker.datatype.number()),
  activity: Factory.each(() => faker.lorem.sentence()),
});

export const TripMock = Factory.Sync.makeFactory<Trip>({
  __typename: "Trip",
  id: Factory.each(() => faker.datatype.uuid()),
  thumbnail: Factory.each(() => faker.image.imageUrl()),
  title: Factory.each(() => faker.lorem.sentence()),
  description: Factory.each(() => faker.lorem.paragraph()),
  budget: Factory.each(() => faker.datatype.number()),
  author: UserMock.build(),
  itinerary: ItineraryMock.buildList(3),
  startDate: Factory.each(() => faker.date.future()),
  endDate: Factory.each(() => faker.date.future()),
  availableSlots: Factory.each(() => faker.datatype.number({ max: 20 })),
  party: UserMock.buildList(3),
});

export const tripsQueryMock = {
  request: {
    query: GET_TRIPS,
  },
  result: {
    data: {
      trips: TripMock.buildList(10),
    },
  },
} as MockedResponse<Query>;

export const profileQueryMock = {
  request: {
    query: GET_PROFILE,
    variables: {
      userId: "1",
    },
  },
  result: {
    data: {
      getProfile: UserMock.build(),
    },
  },
} as unknown as MockedResponse<Query>;

export const myTripsQueryMock = {
  request: {
    query: GET_TRIPS,
    variables: {
      userId: "1",
    },
  },
  result: {
    data: {
      getMyTrips: TripMock.buildList(3),
    },
  },
} as unknown as MockedResponse<Query>;
