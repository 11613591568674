import {
  Input,
  Layout,
  Typography,
  Row,
  Col,
  DatePicker,
  Divider,
  Button,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function SearchBar() {
  const gaEventTracker = useAnalyticsEventTracker("search");
  return (
    <Layout style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <Title level={1} style={{ textAlign: "center", margin: "8px" }}>
        A trusted community of travelers like you!
      </Title>
      <Title level={3} style={{ textAlign: "center", margin: "8px" }}>
        Search, create or join upcoming trips by members
      </Title>
      <Row
        style={{
          alignItems: "center",
          marginTop: "16px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Col
          md={{ span: 12, offset: 6 }}
          xs={{ span: 24, offset: 0 }}
          style={{
            display: "flex",

            border: "1px solid #f0f0f0",
            borderRadius: "8px",
            padding: "10px",
          }}
          className="search-bar"
        >
          <Input
            placeholder="Where do you plan to go?"
            bordered={false}
            style={{ flex: "1" }}
          />
          <Divider
            type="vertical"
            className="search-divider"
            style={{
              borderLeft: "2px solid rgba(0, 0, 0, 0.09)",
              height: "1.8em",
            }}
          />
          <RangePicker bordered={false} style={{ flex: "1" }} />
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: "24px" }}>
        <Button
          type="primary"
          size="large"
          icon={<SearchOutlined />}
          style={{
            paddingRight: "100px",
            paddingLeft: "100px",
            height: "50px",
            borderRadius: "0",
            background: "red",
            border: "none",
            fontWeight: "bold",
          }}
          onClick={() => gaEventTracker("search", "click")}
        >
          Search
        </Button>
      </Row>
    </Layout>
  );
}
