import * as React from "react";
import { AuthReducer, initialState, User, AuthAction } from "./reducer";

const AuthStateContext = React.createContext<User | null>(null);
const AuthDispatchContext =
  React.createContext<React.Dispatch<AuthAction> | null>(null);

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (!context || context === undefined) {
    throw new Error("useAuthState must be used with AuthProvider");
  }
  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (!context || context === undefined) {
    throw new Error("useAuthDispatch must be used with AuthProvider");
  }
  return context;
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, dispatch] = React.useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
