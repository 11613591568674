import { Layout, Typography } from "antd";
const { Footer } = Layout;
const { Paragraph } = Typography;
const noMargin = { marginBottom: "0" };
export default function FooterPage() {
  return (
    <Footer
      style={{
        padding: "15px 50px",
        background: "#fff",
        border: "1px solid #f0f0f0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paragraph style={{ flex: "1", ...noMargin }}>
        &copy; {`${new Date().getFullYear()} • All Rights Reserved`}
      </Paragraph>
      {/* <Paragraph
        style={{
          flex: "1",
          textAlign: "center",
          justifyContent: "center",
          ...noMargin,
        }}
      >
        Made with love from Kenya
      </Paragraph> */}
      <ul
        style={{
          flex: "1",
          listStyleType: "none",
          display: "inline-flex",
          textAlign: "end",
          justifyContent: "end",
          marginBottom: "0",
        }}
        className="footer-menu"
      >
        <li>Terms</li>
        <li>Privacy</li>
        <li>About</li>
        <li>Contact</li>
      </ul>
    </Footer>
  );
}
