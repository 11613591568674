import { Layout } from "antd";
import SearchBar from "../components/SearchBar";
import Listings from "./Listings";

const { Content } = Layout;
export function Home() {
  return (
    <Layout>
      <Content>
        <SearchBar />
      </Content>
      <Content style={{ background: "#fff" }}>
        <Listings />
      </Content>
    </Layout>
  );
}
