import * as React from "react";
import {
  Card,
  Avatar,
  Typography,
  Image,
  Modal,
  Carousel,
  Row,
  Col,
  Table,
  Button,
} from "antd";

const { Meta } = Card;
const { Paragraph } = Typography;

type Author = {
  firstName: string;
  lastName: string;
  avatar: string;
};
interface ListingCardProp {
  thumbnail: string;
  title: string;
  author: Author;
  description: string;
  budget: number;
}

export default function ListingCard({
  thumbnail,
  title,
  author,
  description,
  budget,
}: ListingCardProp) {
  const [visible, setVisible] = React.useState(false);

  const data = [
    { key: "1", date: "Day 1", activity: "Description of day 1 activity" },
    { key: "2", date: "Day 2", activity: "Description of day 2 activity" },
    { key: "3", date: "Day 3", activity: "Description of day 3 activity" },
    { key: "4", date: "Day 4", activity: "Description of day 4 activity" },
  ];
  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Activity", dataIndex: "activity", key: "activity" },
  ];
  const showModal = () => setVisible(true);
  const handleCloseModal = () => setVisible(false);
  const { firstName, lastName, avatar } = author;
  return (
    <>
      <Card
        bordered={false}
        hoverable={true}
        size="default"
        onClick={showModal}
        cover={<Image alt={title} src={thumbnail} preview={false} />}
      >
        <div>
          <Meta
            className="listing-card-info"
            avatar={<Avatar src={avatar} />}
            title={`Created by ${firstName} ${lastName}`}
            description={`${description
              .charAt(0)
              .toLocaleUpperCase()}${title.slice(1)}`}
          />
          <div style={{ marginLeft: "15%", fontSize: "13px" }}>
            <div>
              <Paragraph
                ellipsis={{
                  rows: 1,
                  tooltip: `${description}`,
                }}
              >
                {`${description}`}
              </Paragraph>
            </div>
            <div style={{ display: "flex" }}>
              <Paragraph style={{ justifyContent: "flex-start", flex: "1" }}>
                June 25 - 2 July
              </Paragraph>
              <Paragraph
                style={{
                  justifyContent: "flex-end",
                  flex: "1",
                  textAlign: "end",
                  fontWeight: "bolder",
                }}
              >
                Ksh. {budget}
              </Paragraph>
            </div>
          </div>
        </div>
      </Card>
      <Modal
        visible={visible}
        centered
        closable={true}
        width={800}
        className="trips-modal"
        onCancel={handleCloseModal}
        footer={false}
      >
        <Row>
          <Col md={24}>
            <Carousel className="trips-carousel">
              <Image src={thumbnail} preview={false} />
              <Image src={thumbnail} preview={false} />
            </Carousel>
            <div style={{ padding: "24px" }}>
              <h2>
                {`${description.charAt(0).toLocaleUpperCase()}${title.slice(
                  1
                )}`}
                <span>{`Created by ${firstName} ${lastName}`}</span>
              </h2>
              <h4>
                <strong>Happening from:</strong> June 25 - 2 July,
                <strong> Cost:</strong> Ksh. {budget}
              </h4>
              <Paragraph>{description}</Paragraph>

              <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                bordered={true}
                size="small"
                title={() => "Itinerary"}
                style={{ marginBottom: "16px" }}
              />
              <Button
                block
                style={{
                  padding: "24px 0",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: "8px",
                }}
              >
                Join {`${firstName}'s`} trip
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
