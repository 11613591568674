import { Modal, Typography, Row, Input, Button } from "antd";

export interface AuthProps {
  visible: boolean;
  closeModal: () => void;
}

const { Title } = Typography;
export default function Login({ visible, closeModal }: AuthProps) {
  return (
    <Modal
      visible={visible}
      closable={true}
      className="auth-modal"
      onCancel={closeModal}
      footer={false}
    >
      <Title level={3}>Log In</Title>
      <Row>
        <Input placeholder="Enter email address" />
        <Input placeholder="Enter password" type={"password"} />
        <Button block>Log In</Button>
      </Row>
    </Modal>
  );
}
